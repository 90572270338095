<template>
  <div class="welcome_email">
    <CRow class="m-0">
      <CCol cols="12" lg="12" class="pb-0">
        <label class="mb-2_5"><b>{{ $t('admin.Specify_recipients_for_welcome_email') }}</b></label>
        <CRow v-for="(filter, index) in welcomeEmail.filters" v-bind:key="index">
          <CCol cols="12" lg="12" class="pt-0 pb-0">
            <div class="mb-2_5 d-flex filters">
              <div class="filter_type">
                <multiselect class="data_table open_absolute"
                              v-model="filter.type" 
                              :options="filter.priority === 1 ? groupFilterTypesPrio1 : groupFilterTypesPrio2" 
                              :multiple="false"                
                              :placeholder="$t('common.Select_filter_type')" 
                              :selectLabel="$t('common.Add_filter_type')" 
                              :selectedLabel="$t('Selected')"
                              :deselectLabel="$t('common.Remove_filter_type')"
                              track-by="type_tag" 
                              label="type_name"
                              :custom-label="customFilterTypeLabel"
                              :disabled="filter.type !== null && filter.type !== undefined"
                              @input="setupTypeFilter(filter.type.type_tag, index);">
                  <span slot="noResult">{{ $t('common.no_types_found') }}</span>
                </multiselect>
              </div>

              <div v-if="filter.type" class="filter_condition ml-2" v-bind:class="{'mr-2' : filter.condition}">
                <multiselect class="data_table open_absolute"
                            v-model="filter.condition" 
                            :options="typeFilterConditions" 
                            :multiple="false"                
                            :placeholder="$t('common.Select_filter_condition')" 
                            :selectLabel="$t('common.Add_filter_condition')" 
                            :selectedLabel="$t('Added')"
                            :deselectLabel="$t('common.Remove_filter_condition')"
                            track-by="condition_tag" 
                            label="condition_name"
                            :custom-label="customFilterConditionLabel"
                            :disabled="filter.condition !== null && filter.condition !== undefined"
                            @input="$forceUpdate();">
                  <span slot="noResult">{{ $t('common.no_conditions_found') }}</span>
                  <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                </multiselect>
              </div>

              <div v-if="filter.type && filter.condition" class="filter_value">
                <div v-if="filter.type.type_tag == 'department'">
                  <multiselect class="data_table open_absolute"
                                v-model="filter.value" 
                                :options="groupFilterDepartments" 
                                :multiple="false"                
                                :placeholder="$t('common.Select_department')"
                                :selectLabel="$t('common.Add_department')" 
                                :selectedLabel="$t('Selected')"
                                :deselectLabel="$t('common.Remove_department')"
                                track-by="department_id" 
                                label="department_name"
                                @search-change="asyncFindDepartment"
                                @input="getWelcomeEmailRecipients();">
                    <span slot="noResult">{{ $t('common.no_departments_found') }}</span>
                    <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                  </multiselect>
                </div>
                <div v-if="filter.type.type_tag == 'team'">
                  <multiselect class="data_table open_absolute"
                                v-model="filter.value" 
                                :options="groupFilterTeams" 
                                :multiple="false"                
                                :placeholder="$t('common.Select_team')" 
                                :selectLabel="$t('common.Add_team')" 
                                :selectedLabel="$t('Selected')"
                                :deselectLabel="$t('common.Remove_team')"
                                track-by="team_id" 
                                label="team_name"
                                @search-change="asyncFindTeam"
                                @input="getWelcomeEmailRecipients();">
                    <span slot="noResult">{{ $t('common.no_teams_found') }}</span>
                    <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                  </multiselect>
                </div>
                <div v-if="filter.type.type_tag == 'user'">
                  <multiselect class="data_table open_absolute"
                                v-model="filter.value" 
                                :options="groupFilterRecipients" 
                                :multiple="false"                
                                :placeholder="$t('common.Set_employee')" 
                                :selectLabel="$t('common.Select_employee')" 
                                :selectedLabel="$t('Selected')"
                                :deselectLabel="$t('common.Deselect_employee')"
                                track-by="user_id_external" 
                                label="label"                        
                                @search-change="asyncFindRecipient"
                                @input="getWelcomeEmailRecipients();">
                    <span slot="noResult">{{ $t('common.no_users_found') }}</span>
                    <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                  </multiselect>  
                </div>
                <div v-if="filter.type.type_tag == 'target_group'">
                  <multiselect class="data_table open_absolute"
                                v-model="filter.value" 
                                :options="groupFilterTargetGroups" 
                                :multiple="false"                
                                :placeholder="$t('common.Select_group')" 
                                :selectLabel="$t('common.Add_group')" 
                                :selectedLabel="$t('Selected')"
                                :deselectLabel="$t('common.Remove_group')"
                                track-by="group_id" 
                                label="title"                      
                                @search-change="asyncFindTargetGroup"
                                @input="getWelcomeEmailRecipients();">
                    <span slot="noResult">{{ $t('common.no_groups_found') }}</span>
                    <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                  </multiselect>  
                </div>                  
                <div v-if="filter.type.type_tag == 'date_started' || filter.type.type_tag == 'date_left'">
                  <multiselect class="data_table open_absolute"
                                v-model="filter.value" 
                                :options="groupFilterValues" 
                                :multiple="false"                
                                :placeholder="$t('common.Select_filter_value')" 
                                :selectLabel="$t('common.Add_filter_value')" 
                                :selectedLabel="$t('Added')"
                                :deselectLabel="$t('common.Remove_filter_value')"
                                track-by="type_value_tag" 
                                label="type_value_name"
                                :custom-label="customFilterTypeValueLabel"
                                @input="getWelcomeEmailRecipients();">
                    <span slot="noResult">{{ $t('common.no_values_found') }}</span>
                  </multiselect>   
                </div>
                <div v-if="userAttributes.includes(filter.type.type_tag)">
                  <CInput v-debounce:1s="getWelcomeEmailRecipients"
                          type="text"
                          class="attribute mb-0"
                          v-model="filter.value">
                  </CInput>
                </div>
              </div>

              <CButton class="ml-2 mr-0 d-inline-block" color="primary" @click="removeFilterOption(index);">
                <i class="fas fa-times"/>
              </CButton>
            </div>
          </CCol>
        </CRow>
        <CRow>
          <CCol cols="12" lg="12" class="pt-0">
            <CButton color="primary" @click="addFilterOption(1);">
              <i class="fas fa-plus mr-1"/>
              <span>{{$t('common.Add_required_filter')}}</span>
            </CButton>
            <CButton color="primary" @click="addFilterOption(2);">
              <i class="fas fa-plus mr-1"/>
              <span>{{$t('common.Add_additional_filter')}}</span>
            </CButton>
          </CCol>
        </CRow>
        <hr class="m-0">
        <CRow class="group_members">
          <CCol cols="6" lg="6" class="pb-0 text-left" v-bind:class="{'pb-0' : !listViewEnabled}">
            <span><b>{{$t("common.Recipients") }} <span v-if="welcomeEmail.recipients.length > 0">({{welcomeEmail.recipients.length}})</span></b></span>                                
          </CCol>
          <CCol cols="6" lg="6" class="pb-0 text-right" v-bind:class="{'pb-0' : !listViewEnabled}">
            <b-switch v-if="welcomeEmail.recipients.length > 0" class="mb-0" v-model="listViewEnabled" size="is-small">{{ $t('common.activate_list_view') }}</b-switch>
          </CCol>
        </CRow>          
        <CRow>
          <CCol cols="12" lg="12" v-bind:class="{'pt-0 pb-0' : !listViewEnabled}">
            <div v-if="listViewEnabled">
              <CRow>
                <CCol cols="12" sm="12" md="12" lg="12" class="pt-0" v-bind:class="{ 'pb-0' : listViewEnabled === true }">
                  <b-table ref="groupTable" class="data_table" :data="welcomeEmail.recipients" :striped="true" :hoverable="true" :mobile-cards="true" :paginated="isPaginated" :per-page="perPage" :current-page.sync="currentPage" :pagination-simple="isPaginationSimple" :pagination-position="paginationPosition">
                    <template slot-scope="props">
                      <b-table-column field="name" :label="$t('common.Name')">
                        <div @click="openSidebarRight('user_profile', { user_id_external:  props.row.user_id_external });" class="pointer">
                          <div v-if="props.row.profile_image" class="profile_image d-inline-block align-middle mr-2" v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile/' + props.row.user_id_external + '/' + props.row.profile_image + '/' + clientToken + ')' }"></div>
                          <div v-else class="profile_icon d-inline-flex align-middle mr-2">
                            <userProfileAvatar :username="props.row.name" :size="25"/>
                          </div>
                          <b>{{props.row.name}}</b>
                        </div>                          
                      </b-table-column>
                      <b-table-column field="department_name" :label="$t('common.Department')">
                        <span>{{props.row.department_name ? props.row.department_name : '-'}}</span>
                      </b-table-column>                        
                      <b-table-column field="team_name" :label="$t('common.Team')">
                        <span>{{props.row.team_name ? props.row.team_name : '-'}}</span>
                      </b-table-column>
                    </template>
                    <template slot="empty">
                      <div class="text-center">
                        <div v-if="welcomeEmail.filters.length > 0">
                          {{ $t('groups.No_users_based_on_filters') }}
                        </div>
                        <div v-else>
                          {{ $t('groups.Add_filters_to_show_members') }}
                        </div>
                      </div>                
                    </template>                
                  </b-table>                    
                </CCol>
              </CRow>
            </div>
            <div v-else>
              <div v-if="welcomeEmail.recipients.length > 0">
                <CRow class="recipients_container">
                  <CCol cols="2" xl="2" lg="3" md="3" sm="12" v-for="employee in currentPageEmployees[employeesCurrentPage - 1]" v-bind:key="employee.user_id_external" class="p-0">
                    <userProfileCard :userData="employee" @click.native="openSidebarRight('user_profile', { user_id_external:  employee.user_id_external });" class="member pointer"/>
                  </CCol>
                </CRow>
                <CRow v-if="welcomeEmail.recipients.length > employeesPerPage">
                  <CCol cols="12" md="12" class="pt-0">
                    <v-pagination class="justify-content-end" v-model="employeesCurrentPage" :length="employeePages" :total-visible="9" prev-icon="mdi-chevron-left" next-icon="mdi-chevron-right"></v-pagination>
                  </CCol>
                </CRow>
              </div>
              <div v-else>
                <CRow>
                  <CCol cols="12" lg="12">
                    <span>{{welcomeEmail.filters.length > 0 ? $t('common.No_employees_based_on_filters') : $t('admin.Add_filters_to_show_welcome_email_recipients')}}</span>
                  </CCol>
                </CRow>
              </div>
            </div>
          </CCol>
        </CRow>                             
      </CCol>
    </CRow>
    <hr class="m-0">
    <CRow class="m-0">
      <CCol cols="12" lg="12">
        <CButton class="m-0" color="primary" @click="confirmModal = true;" :disabled="welcomeEmail.recipients.length === 0 || sendButtonDisabled === true">
          <div v-if="sendingWelcomeEmail === false">
            <span><i class="fa-solid fa-paper-plane m-1"></i>{{$t('admin.Send_welcome_email')}}</span>
          </div>
          <div v-else class="d-flex align-items-center">
            <loadingSpinner mode="inline"/>
            <span class="ml-1">{{$t('admin.Sending_welcome_email')}}</span>
          </div>
        </CButton>        
      </CCol>
    </CRow>

    <b-modal :can-cancel="['x']" :active.sync="confirmModal" :width="960" scroll="keep">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">
          <span>{{$t('admin.Send_welcome_email')}}</span>
        </CCardHeader>
        <CCardBody class="pb-0">
          <CRow>
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <span>{{$t('admin.Are_you_sure_to_send_welcome_email')}}</span>
            </CCol>
          </CRow>
          <CRow class="recipients_container">
            <CCol cols="2" xl="2" lg="3" md="3" sm="12" v-for="employee in currentPageEmployees[confirmModalCurrentPage - 1]" v-bind:key="employee.user_id_external" class="p-0">
              <userProfileCard :userData="employee" class="member"/>
            </CCol>
          </CRow>
          <CRow v-if="welcomeEmail.recipients.length > employeesPerPage">
            <CCol cols="12" md="12" class="pt-0">
              <v-pagination class="justify-content-end" v-model="confirmModalCurrentPage" :length="employeePages" :total-visible="9" prev-icon="mdi-chevron-left" next-icon="mdi-chevron-right"></v-pagination>
            </CCol>
          </CRow>
        </CCardBody>
        <CCardFooter>
          <CButton color="primary" @click="confirmModal = false; sendWelcomeEmail();"><i class="fas fa-check mr-1"/>{{$t('admin.Send_welcome_email')}}</CButton>
          <CButton color="secondary" @click="confirmModal = false; confirmModalCurrentPage = 1"><i class="fas fa-times mr-1"/>{{$t('cancel')}}</CButton>
        </CCardFooter>
      </CCard>
    </b-modal>    
  </div>  
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect';
import loadingSpinner from '@/components/common/loadingSpinner.vue';

import userProfileAvatar from "@/components/common/userProfileAvatar.vue";
import userProfileCard from "@/components/common/userProfileCard.vue";

export default {
  name: 'SendWelcomEmail',
  components: {
    Multiselect,
    loadingSpinner,
    userProfileAvatar,
    userProfileCard
  },  
  data() {
    return {
      apiBaseUrl: null,
      cdnBaseUrl: null,
      clientToken: null,
      environmentTag: null,
      companyIdExternal: null,      
      welcomeEmail: {
        recipients: [],
        filters: []
      },
      groupFilterTypesPrio1: [],
      groupFilterTypesPrio2: [],
      groupFilterConditions: [],
      groupFilterValues: [],
      groupFilterTargetGroups: [], 
      groupFilterRecipients: [],
      groupFilterDepartments: [],
      groupFilterTeams: [],
      typeFilterConditions: [],      
      typeFilterValues: [],
      currentPageEmployees: {},
      employeesCurrentPage: 1,
      employeesPerPage: 12,
      employeePages: 0,       
      userAttributes: ['hours_on_contract', 'meyer_briggs', 'disc', 'office_based', 'division', 'language', 'persona_1', 'persona_2', 'persona_3', 'work_location', 'user_function', 'years_of_service'],
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 8,      
      listViewEnabled: false,
      confirmModal: false,
      confirmModalCurrentPage: 1,
      sendButtonDisabled: false,
      sendingWelcomeEmail: false
    }
  },
  methods: {
    sendWelcomeEmail() {
      // Disable the send button
      this.sendButtonDisabled = true;
      // Show the loader
      this.sendingWelcomeEmail = true;
      // Set the params
      let params = {};
      params.recipients = this.welcomeEmail.recipients;
      // Send the welcome email
      axios.post(process.env.VUE_APP_API_URL + '/v1/core/verifiction/email/users', params)
      .then(res => {
        this.$buefy.toast.open({ message: this.$t('organisation.Welcome_email_sent'), type: 'is-success', duration: 2000 });
        // Reset the welcome email data
        this.resetWelcomeEmailData();
        // Stop the loader
        this.sendingWelcomeEmail = false;
        // Enable the send button
        this.sendButtonDisabled = false;
        // Reset the confirmModalCurrentPage value
        this.confirmModalCurrentPage = 1;
      })
      .catch(err => {
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        // Stop the loader
        this.sendingWelcomeEmail = false;
        // Enable the send button
        this.sendButtonDisabled = false;
        // Reset the confirmModalCurrentPage value
        this.confirmModalCurrentPage = 1;        
      })
    },
    resetWelcomeEmailData() {
      this.welcomeEmail = {
        recipients: [],
        filters: []
      };
    },    
    getWelcomeEmailRecipients() {
      let params = {};
      params.filters = this.welcomeEmail.filters.filter(function (el) {
        return el.type != undefined && el.value != undefined;
      });
      
      if(params.filters.length > 0) {
        // Get the recipients if there are filters available
        axios.post(process.env.VUE_APP_API_URL + '/v1/core/targetgroup/members', params)
        .then(res => {
          this.welcomeEmail.recipients = res.data.data;
          // Enable the pagination if necessary
          (this.welcomeEmail.recipients.length > this.perPage) ? this.isPaginated = true : this.isPaginated = false;         
          // Set the pagination to the first page
          this.employeesCurrentPage = 1;
          // Reset the employeePages value
          this.employeePages = 0;
          // Loop through the Moment to set the pagination            
          for (let i = 0; i < this.welcomeEmail.recipients.length; i = i + this.employeesPerPage) {
            this.currentPageEmployees[this.employeePages] = this.welcomeEmail.recipients.slice(i, i + this.employeesPerPage);
            this.employeePages++;
          }
          // Check if the pagination for the table should be enables
          this.welcomeEmail.recipients.length > this.perPage ? this.isPaginated = true : this.isPaginated = false;        
          // Force update
          this.$forceUpdate();
        })
        .catch(err => {
          console.error(err); 
        });
      } else {
        // Reset the recipients array if there are no filters available
        this.welcomeEmail.recipients = [];
      }
    },
    addFilterOption(priority) {
      // Add new filter to filters array
      this.welcomeEmail.filters.push({priority: priority});
      // Force update
      this.$forceUpdate();
    },    
    removeFilterOption(index) {
      // Remove filter from filters array
      this.welcomeEmail.filters.splice(index, 1);      
      // Disable the list view when there are no filters left
      if(this.welcomeEmail.filters.length === 0 && this.listViewEnabled) this.listViewEnabled = false;
      // Update the recipients
      this.getWelcomeEmailRecipients();
      // Force update
      this.$forceUpdate();
    },
    getGroupFilterTypes() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/targetgroups/filters/types')
      .then(res => {      
        let groupFilterTypes = res.data.data;

        this.groupFilterTypesPrio1 = groupFilterTypes.filter(item => item.priority == 1);
        this.groupFilterTypesPrio2 = groupFilterTypes.filter(item => item.priority == 2);
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getGroupFilterTypeValues() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/targetgroups/filters/types/values')
      .then(res => {
        this.groupFilterValues = res.data.data;
        // Set the typeFilterValues array
        this.typeFilterValues = this.groupFilterValues;        
      })
      .catch(err => {
        console.error(err); 
      });
    },    
    getGroupFilterConditions () {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/targetgroups/filters/conditions')
      .then(res => {
        this.groupFilterConditions = res.data.data;
        // Set the typeFilterConditions array
        this.typeFilterConditions = this.groupFilterConditions;        
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getTypeFilterConditions(filterTypeTag, filterIndex) {
      // Reset the typeFilterConditions array
      this.typeFilterConditions = this.groupFilterConditions;
      // Filter the typeFilterConditions based on the filter type tag
      this.typeFilterConditions = this.typeFilterConditions.filter( i => i.available_for_filter_type_tags.includes(filterTypeTag));
      // Auto set the condition if the filter type has only one available
      if(this.typeFilterConditions.length === 1) this.welcomeEmail.filters[filterIndex].condition = this.typeFilterConditions[0];
    },    
    getTypeFilterValues(filterTypeTag) {
      // Reset the typeFilterValues array
      this.typeFilterValues = this.typeFilterValues;
      // Filter the typeFilterValues based on the filter type tag
      this.typeFilterValues = this.typeFilterValues.filter( i => i.available_for_filter_type_tags.includes(filterTypeTag));
    },
    setupTypeFilter(filterTypeTag, filterIndex) {
      // Get the filter type conditions
      this.getTypeFilterConditions(filterTypeTag, filterIndex);
      // Get the filter values
      if(filterTypeTag === 'department') {
        this.getGroupFilterDepartments();        
      } else if(filterTypeTag === 'team') {
        this.getGroupFilterTeams();        
      } else if(filterTypeTag === 'user') {
        this.getGroupFilterRecipients();        
      } else if(filterTypeTag === 'target_group') {
        this.getGroupFilterTargetGroups();        
      }      
      // Force update
      this.$forceUpdate();
    },
    customFilterTypeLabel({type_tag}) {
      return this.$t('filter.' + type_tag);
    },    
    customFilterConditionLabel({condition_tag}) {
      return this.$t('condition.' + condition_tag);
    },    
    customFilterTypeValueLabel({type_value_tag}) {
      return this.$t('filter_value.' + type_value_tag);
    },
    getGroupFilterTargetGroups() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/targetgroups/multiselect')
      .then(res => {
        this.groupFilterTargetGroups = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getGroupFilterRecipients() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/organisation/employees/multiselect')
      .then(res => {
        this.groupFilterRecipients = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getGroupFilterDepartments() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/organisation/departments/multiselect')
      .then(res => {
        this.groupFilterDepartments = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getGroupFilterTeams() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/organisation/teams/multiselect')
      .then(res => {
        this.groupFilterTeams = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    asyncFindTargetGroup(query) {
      let searchTerm = query;
      this.groupFilterTargetGroups = [];
      
      if(searchTerm.length >= 2) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/targetgroup/' + searchTerm)
        .then(res => {      
          this.groupFilterTargetGroups = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },    
    asyncFindRecipient(query) {
      let searchTerm = query;
      this.groupFilterRecipients = [];
      
      if(searchTerm.length >= 2) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/recipients/' + searchTerm)
        .then(res => {      
          this.groupFilterRecipients = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },      
    asyncFindDepartment(query) {
      let searchTerm = query;
      this.groupFilterDepartments = [];
      
      if(searchTerm.length >= 2) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/departments/' + searchTerm)
        .then(res => {      
          this.groupFilterDepartments = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },
    asyncFindTeam(query) {
      let searchTerm = query;
      this.groupFilterTeams = [];

      if(searchTerm.length >= 2) {
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/teams/' + searchTerm)
        .then(res => {      
          this.groupFilterTeams = res.data.data;    
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    }
  },
  mounted: function() {
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.cdnBaseUrl = process.env.VUE_APP_CDN_URL;
    this.clientToken = localStorage.getItem('token');
    if(localStorage.getItem('environmentTag') !== null) this.environmentTag = localStorage.getItem('environmentTag');
    if(localStorage.getItem('companyIdExternal') !== null) this.companyIdExternal = localStorage.getItem('companyIdExternal');

    this.getGroupFilterTypes();
    this.getGroupFilterConditions();
    this.getGroupFilterTypeValues();
  }
}
</script>
