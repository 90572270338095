<template>
  <div>
    <div v-if="checkMultiplePermissions(['demo.loyalty.grantpoints', 'demo.loyalty.removepoints'])" :class="{'add_border_bottom' : checkMultiplePermissions(['demo.loyalty.store.simulateorders', 'demo.store.removeorders'])}">
      <!-- Grant Loyalty Points -->
      <CRow v-if="checkPermission('demo.loyalty.grantpoints')" class="m-0">
        <CCol cols="12" lg="12">
          <label>{{$t('demo.Randomly_grant_random_coins_to_demo_accounts_of_company', { company_name: companyData.details.company_name })}}</label>
          <div class="d-flex align-items-center">
            <CButton color="primary" @click="grantLoyaltyPoints();" class="fit-content m-0">
              <span><i class="fa-solid fa-play mr-1"></i>{{$t('demo.Randomly_grant_coins')}}</span>
            </CButton>
          </div>
        </CCol>
      </CRow>
      <!-- Remove Loyalty Points -->
      <CRow v-if="checkPermission('demo.loyalty.removepoints')" class="m-0">
        <CCol cols="12" lg="12" class="pt-0">
          <span @click="removeLoyaltyPoints()" class="pointer remove"><i class="fa-solid fa-trash mr-1"></i>{{$t('demo.Remove_coins')}}</span>
        </CCol>
      </CRow>
    </div>  
    <div v-if="checkMultiplePermissions(['demo.loyalty.store.simulateorders', 'demo.store.removeorders'])">
      <!-- Simulate Loyalty Store orders -->
      <CRow v-if="checkPermission('demo.loyalty.store.simulateorders')" class="m-0">
        <CCol cols="12" lg="12">
          <label>{{$t('demo.Simulate_shop_orders_for_company', { company_name: companyData.details.company_name })}}</label>
          <div class="d-flex align-items-center">
            <CInput type="number" step="1" min="0" class="flex-grow-1 mb-0" v-model="demoOrderAmount"/>
            <CButton color="primary" @click="simulateLoyaltyStoreOrders();" class="ml-2 mr-0" :disabled="demoOrderAmount <= 0">
              <span><i class="fa-solid fa-play mr-1"></i>{{$t('demo.Simulate_orders')}}</span>
            </CButton>
          </div>
        </CCol>
      </CRow>
      <!-- Remove Loyalty Store orders -->
      <CRow v-if="checkPermission('demo.loyalty.store.removeorders')" class="m-0">
        <CCol cols="12" lg="12" class="pt-0">
          <span @click="removeLoyaltyStoreOrders()" class="pointer remove"><i class="fa-solid fa-trash mr-1"></i>{{$t('demo.Remove_orders')}}</span>
        </CCol>
      </CRow>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Learn',
  props: ['companyData', 'platformPermissions'],  
  data() {
    return {
      demoOrderAmount: 0
    }
  },
  methods: {
    grantLoyaltyPoints() {
      axios.post(process.env.VUE_APP_API_URL + '/v1/demo/loyalty/points')
      .then(res => { 
        // Show a success notice
        this.$buefy.toast.open({ message: this.$t('demo.Coins_granted_randomly'), type: 'is-success', duration: 2000 });
      })
      .catch(err => {
        console.error(err);
      });
    },
    removeLoyaltyPoints() {
      axios.delete(process.env.VUE_APP_API_URL + '/v1/demo/loyalty/points')
      .then(res => { 
        // Show a success notice
        this.$buefy.toast.open({ message: this.$t('demo.Points_removed'), type: 'is-success', duration: 2000 });
      })
      .catch(err => {
        console.error(err);
      });
    },    
    simulateLoyaltyStoreOrders() {
      // Set the params
      let params = {};
      params.order_amount = this.demoOrderAmount;      
      // Create the demo orders      
      axios.post(process.env.VUE_APP_API_URL + '/v1/demo/loyalty/store/orders', params)
      .then(res => { 
        // Show a success notice
        this.$buefy.toast.open({ message: this.$t('demo.Demo_orders_simulated'), type: 'is-success', duration: 2000 });
        // Reset the demoOrderAmount
        this.demoOrderAmount = 0;        
      })
      .catch(err => {
        console.error(err);
      });
    },
    removeLoyaltyStoreOrders() {
      axios.delete(process.env.VUE_APP_API_URL + '/v1/demo/loyalty/store/orders')
      .then(res => { 
        // Show a success notice
        this.$buefy.toast.open({ message: this.$t('demo.Demo_orders_removed'), type: 'is-success', duration: 2000 });     
      })
      .catch(err => {
        console.error(err);
      });
    },    
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    },
    checkMultiplePermissions(permissionTags) {
      var _this = this;
      var permissionsValue = 0;

      permissionTags.forEach(function(permission) {
        permissionsValue = permissionsValue + _this.platformPermissions.includes(permission);
      });

      return (permissionsValue > 0);
    }    
  }
}
</script>