<template>
  <CRow v-if="companyData" class="m-0">
    <CCol cols="12" lg="12" class="pt-0">
      <span class="d-block mb-2"><b>{{$t('admin.Loyalty_store_locked2')}}</b></span>        
      <b-switch class="m-0" v-model="companyLoyaltyStoreLocked" size="is-small" @input="confirmModal = true;">
        <span>{{companyLoyaltyStoreLocked ? $t('Yes') : $t('No')}}</span>
      </b-switch>
      
      <b-modal :can-cancel="['x']" :active.sync="confirmModal" :width="960" scroll="keep">
        <CCard class="mb-0">
          <CCardHeader class="pb-0">
            <span>{{companyLoyaltyStoreLocked ? $t('admin.Lock_loyalty_store_for_company2') : $t('admin.Unlock_loyalty_store_for_company2')}}</span>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol cols="12" lg="12" class="pt-0 pb-0">
                <span>{{companyLoyaltyStoreLocked ? $t('admin.Are_you_sure_to_lock_loyalty_store_for_company2', { company_name : companyData.details.company_name}) : $t('admin.Are_you_sure_to_unlock_loyalty_store_for_company2', { company_name : companyData.details.company_name})}}</span>
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter>
            <CButton color="primary" @click="confirmLoyaltyStoreStatusUpdate();"><i class="fas fa-check mr-1"/>{{companyLoyaltyStoreLocked ? $t('admin.Lock_loyalty_store2') : $t('admin.Unlock_loyalty_store2')}}</CButton>
            <CButton color="secondary" @click="cancelLoyaltyStoreStatusUpdate()"><i class="fas fa-times mr-1"/>{{ $t('Cancel') }}</CButton>
          </CCardFooter>
        </CCard>
      </b-modal>    
    </CCol>
  </CRow>
</template>

<script>
import axios from 'axios'

export default {
  name: 'LoyaltyStoreLocked',
  props: ['companyData'],
  watch: {
    companyData: {
      handler() {
        if(this.companyData && this.companyData.loyalty_store_locked) {
          this.companyLoyaltyStoreLocked = this.companyData.loyalty_store_locked;
        }
      },
      deep: true,
      immediate: true
    }
  },  
  data() {
    return {
      updateApiPath: null,
      updateApiMessage: null,
      companyLoyaltyStoreLocked: false, 
      confirmModal: false
    }
  },
  methods: {
    confirmLoyaltyStoreStatusUpdate() {
      // Set the updateApiPath and updateApiMessage values
      if(this.companyLoyaltyStoreLocked) {
        this.updateApiPath = 'lock';
        this.updateApiMessage = this.$t('admin.Loyalty_store_locked_for_company', { company_name : this.companyData.details.company_name});
      } else {
        this.updateApiPath = 'unlock';
        this.updateApiMessage = this.$t('admin.Loyalty_store_unlocked_for_company', { company_name : this.companyData.details.company_name});
      } 

      axios.put(process.env.VUE_APP_API_URL + '/v1/loyalty/store/' + this.updateApiPath)
      .then(res => {
        this.$buefy.toast.open({ message: this.updateApiMessage, type: 'is-success', duration: 2000 });
        // Close the modal
        this.confirmModal = false;        
      })
      .catch(err => {
        console.error(err); 
      });
    },
    cancelLoyaltyStoreStatusUpdate() {
      this.companyLoyaltyStoreLocked = !this.companyLoyaltyStoreLocked;
      // Close the modal
      this.confirmModal = false;
    }  
  }
}
</script>